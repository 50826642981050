<template>
  <div>
    <div>
      <section class="auction-list-page" style="margin-bottom: 80px"></section>
      <carlist></carlist>
    </div>
  </div>
</template>
<script>
//import CarList from "@/components/CarList";
export default {
  name: "Auction",
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  // created() {
  //   if (this.loggedIn) {
  //     if (
  //       !this.$store.state.auth.user[0].customerId == "" ||
  //       !this.$store.state.auth.user[0].customerId == " "
  //     ) {
  //       this.$router.push("/Auction").catch(() => {
  //         "home";
  //       });
  //     } else {
  //       this.$router.push("/").catch(() => {
  //         "home";
  //       });
  //     }
  //   }
  // },
  mounted() {
    // console.log(this.$store.state.auth.status.loggedIn);
    
  },
};
</script>
<style>
/* @media (min-width: 1400px) {
  .bidz-container-list {
    max-width: 1100px;
  }
} */
@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .bidlistwidthfor768-991 {
    width: 33.33%;
  }
  
  .bidlistImagewrap {
    position: relative;
    height: 100%;
  }
  .bidlistImagewrap img {
    width: 100%;
    height: 60%;
    overflow: auto;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .bidBtn {
    width: 100%;
  }
  .hideon768-991 {
    display: none;
  }
  .bidzNumberz {
    background-color: #018f06;
    padding: 5px;
    color: white;
    border-radius: 5px;
    margin: 0px auto;
  }
  .detailsButtonz {
    background-color: gray;
    color: white;
    width: 100%;
    padding: 5px;
    border-radius: 5px;
  }
}

.bidzNumberz,
.detailsButtonz {
  display: none;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #ffffff !important;
  background-color: #050029;
  border-color: #dee2e6 #dee2e6 #fff;
  font-weight: 500;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background-color: #858585;
  color: #ffffff !important;
}
</style>
